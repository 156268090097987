/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { App, nextTick } from 'vue'
import * as dd from 'dingtalk-jsapi'
import { getUrlParam } from 'wd-platform-utils'
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { isMobile } from '@/utils/request'
import { getCORPID } from '@/utils/prototypeAPI'
import { currentUser } from '@/service/userService'
import { useUserStore } from '@/store/modules/user'
import { useLoadingStore } from '@/store/modules/loading'
import { DingScanLogin, getUserLoginToken } from '@/service/loginService'
import { RedirectRouteParam, getRedirectType, mobileGetRedirectType } from '@/enmu/redirect'

export const whiteUrlList = ['/login', '/external/msgPrompt', '/redirect', '/notmobile', '/notpc']
const excludeUrlList = [
    '/courseProducts/detail/',
    '/examination/commonSense/detail/',
    '/examination/flowPath/detail/',
    '/teacherServer/flowUpDetail/',
    '/teacherServer/modelDetail/',
    '/teachingResearch/qualified/detail/',
    '/teachingResearch/book/detail/',
    '/teachingResearch/achievemen/detail/',
    '/trialClass/detail/',
    '/collegeDatabase/compareDetail/',
    '/collegeDatabase/detail/',
    '/adjustDatabase/detail/',
    '/collegeDatabase/compareMINIDetail/',
    '/dingCode'
]

const routes: Array<RouteRecordRaw> = [
    {
        path: '/forward',
        name: 'Forward',
        meta: {
            title: '浏览器重定向'
        },
        component: () => import('@/views/redirect/index.vue')
    },
    {
        path: '/notmobile',
        name: 'Notmobile',
        meta: {
            title: '非手机端页面'
        },
        component: () => import('@/views/error/notMobile.vue')
    },
    {
        path: '/notpc',
        name: 'Notpc',
        meta: {
            title: '非PC端页面'
        },
        component: () => import('@/views/error/notPC.vue')
    },
    {
        path: '/redirect',
        name: 'Redirect',
        meta: {
            title: '项目跳转'
        },
        component: () => import('@/views/redirect/redirect.vue')
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            title: '文都中台应用'
        },
        component: () => isMobile() ? import('@/views/mobile/index.vue') : import('@/views/home/index.vue')
    },
    {
        path: '/external',
        name: '对外',
        component: () => import('@/layout/MainContent.vue'),
        children: [
            {
                path: 'orderSuccess',
                name: '订单提交成功',
                component: () => import('@/views/external/orderSuccess.vue')
            },
            {
                path: 'msgPrompt',
                name: '消息提示',
                component: () => import('@/views/external/msgPrompt.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录'
        },
        component: () => isMobile() ? import('@/views/mlogin/index.vue') : import(/* webpackChunkName: "login" */'@/views/login/index.vue')
    },
    {
        path: '/auth:pathMatch(.*)',
        name: 'Auth',
        meta: {
            title: '权限管理',
            code: 'auth'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/channel:pathMatch(.*)',
        name: 'Channel',
        meta: {
            title: '文都营销获客',
            code: 'channel'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/statistics:pathMatch(.*)',
        name: 'statistics',
        meta: {
            title: '文都数据统计',
            code: 'statistics'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/consult:pathMatch(.*)',
        name: 'consult',
        meta: {
            title: '文都咨询工具',
            code: 'consult'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/product:pathMatch(.*)',
        name: 'product',
        meta: {
            title: '文都产品管理',
            code: 'product'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/morder:pathMatch(.*)',
        name: 'morder',
        meta: {
            title: '选课中心',
            code: 'morder'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/order:pathMatch(.*)',
        name: 'order',
        meta: {
            title: '订单管理',
            code: 'order'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/educational:pathMatch(.*)',
        name: 'educational',
        meta: {
            title: '教务管理',
            code: 'educational'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/marketing:pathMatch(.*)',
        name: 'marketing',
        meta: {
            title: '营销系统',
            code: 'marketing'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/student:pathMatch(.*)',
        name: 'student',
        meta: {
            title: '线索管理(PC端)',
            code: 'student'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/contract:pathMatch(.*)',
        name: 'contract',
        meta: {
            title: '电子合同',
            code: 'contract'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/consultsys:pathMatch(.*)',
        name: 'consultsys',
        meta: {
            title: '咨询助手(后台)',
            code: 'consultsys'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/consulthelper:pathMatch(.*)',
        name: 'consulthelper',
        meta: {
            title: '咨询助手(pc)',
            code: 'consulthelper'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/evaluation:pathMatch(.*)',
        name: 'evaluation',
        meta: {
            title: '测评系统(后台)',
            code: 'evaluation'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/views/micro/index.vue')
    },
    {
        path: '/mstudent:pathMatch(.*)',
        name: 'mstudent',
        meta: {
            title: '线索管理(移动端)',
            code: 'mstudent'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/mconsulthelper:pathMatch(.*)',
        name: 'mconsulthelper',
        meta: {
            title: '咨询助手(移动端)',
            code: 'mconsulthelper'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/meduadm:pathMatch(.*)',
        name: 'meduadm',
        meta: {
            title: '教学服务系统(移动端)',
            code: 'meduadm'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/mperformance:pathMatch(.*)',
        name: 'mperformance',
        meta: {
            title: '绩效数字化核算系统(移动端)',
            code: 'mperformance'
        },
        component: () => import(/* webpackChunkName: "micro" */ '@/layout/microM.vue')
    },
    {
        path: '/error/:code',
        name: 'error',
        meta: {
            title: '异常详情'
        },
        component: () => import(/* webpackChunkName: "error" */'@/views/error/index.vue')
    },
    {
        path: '/dingCode',
        name: 'dingCode',
        meta: {
            title: '钉钉Code'
        },
        component: () => import(/* webpackChunkName: "error" */'@/views/dingCode/index.vue')
    },
    {
        path: '/:pathMatch(.*)',
        name: 'NotFound',
        meta: {
            title: '异常详情'
        },
        redirect: '/error/404'
    }
]
export const router = createRouter({
    history: createWebHistory('/'),
    routes
})

router.beforeEach(async (to: RouteLocationNormalized, _: RouteLocationNormalized, next: any) => {
    const userStore = useUserStore()
    console.log(to, 'to')
    console.log(_, 'from')
    console.log(userStore.getUserInfo, 'getUserInfo')
    console.log(userStore.getUserToken, 'getUserToken')
    if (excludeUrlList.findIndex(item => to.path.indexOf(item) > -1) > -1) {
        next()
    } else if (whiteUrlList.includes(to.path)) {

        if (_.query.redirect) {
            next(`/login?redirect=${_.query.redirect}`)
        } else {
            next()
        }
    } else {

        if (to.query.ticket || to.query.code) {
            nextTick(() => {
                useLoadingStore().setLoadingState(true)
            })
            loginByThird(to.query as ThirdLoginParams)
        } else if (!whiteUrlList.includes(to.path) && (!userStore.getUserToken) && (to.query.ticket || to.query.code)) {
            nextTick(() => {
                useLoadingStore().setLoadingState(true)
            })
            loginByThird(to.query as ThirdLoginParams)
        } else if (!whiteUrlList.includes(to.path) && (!userStore.getUserToken)) {
            userStore.setToken(null)
            next('/login')
        } else if ((to.path !== '/login') && (userStore.getUserInfo === null) && (userStore.getUserToken !== null)) {
            // 兼容只有教师权限没有学管师权限的内部员工已经登录过了（有token时），请求用户信息接口报错的问题（不请求用户信息接口）
            const appCode = getUrlParam('appCode')
            if (appCode && appCode === 'teacher_client') {
                next()
            } else {
                // 其他项目正常请求用户信息接口
                const userResult = await currentUser()
                if (userResult) {
                    userStore.setUserInfo(userResult.result)
                    next()
                } else {
                    next('/login')
                }
            }
        } else {
            next()
        }
        nextTick(() => {
            useLoadingStore().setLoadingState(true)
        })
    }
})

router.afterEach(() => {
    nextTick(() => {
        useLoadingStore().setLoadingState(false)
    })
})
export const loginByThird = async ({ ticket, appid, client_id, code, clientType, otherType, id, entrance, orgId }: any): Promise<void> => {
    const appCode = getUrlParam('appCode')
    if (!ticket && !appid && !client_id && !code) {
        router.push('/login')
    } else if (appCode === 'cis' || appCode === 'product' || appCode === 'educational' || appCode === 'permission') {
        // cis 不需要走登录流程 直接跳转在子应用进行登录
        window.location.href = getRedirectType[appCode] + getParamHref() + '&code=' + code
    } else {
        let resultData = null
        if (clientType === 'scan') {
            resultData = await DingScanLogin({
                code,
                appid,
                clientType: 'dingding',
                appCode,
            })
        } else {
            resultData = await getUserLoginToken({ ticket: ticket || '', appid: appid || '', code: code || '', clientType: clientType || '', appCode })
        }

        if (resultData) {
            useUserStore().setToken(resultData.result.accessToken)
            useUserStore().setClientType(clientType === 'scan' ? 'dingding' : clientType)
            // 兼容只有教师权限没有学管师权限的内部员工无机构id时，获取token成功后，请求获取用户信息接口报错的问题
            if (appCode && appCode === 'teacher_client') {
                // 跳转教学服务平台
                window.location.href = (process.env.VUE_APP_ZNPT as string) + getRedirectType[appCode] + getParamHref()
                return
            }
            if (orgId) {
                await useUserStore().changeOrgId(orgId)
            }
            const userResult = await currentUser()
            if (userResult) {
                useUserStore().setUserInfo(userResult.result)
                if (appCode && appCode !== 'znpt') {
                    if (isMobile()) {
                        // 移动端在钉钉内部打开
                        if (appCode && appCode === 'finance') { // 兼容老逻辑审批移动端
                            router.push(`/mstudent/finance/approvalInformation/${id}/${entrance}/${orgId}`)
                            return
                        } else if (appCode && appCode === 'tjbg') { // 兼容老逻辑调剂报告
                            const id = getUrlParam('tjId') ? getUrlParam('tjId') : 1
                            router.push(mobileGetRedirectType.mtjbg + id)
                            return
                        }
                        const path: string = mobileGetRedirectType[appCode] + (getUrlParam('pageUrl') ? (getUrlParam('pageUrl') as string) : '/')
                        console.log(path, getQuery())
                        router.push({
                            path,
                            query: getQuery()
                        })
                        return
                    } else {
                        // pc端跳到默认浏览器打开
                        window.location.href = (process.env.VUE_APP_ZNPT as string) + getRedirectType[appCode] + getParamHref()
                        return
                    }
                }
                window.location.href = (process.env.VUE_APP_ZNPT as string) + getParamHref()
                // router.push('/')
            }
        } else {
            if (getUrlParam('appCode') === 'teacher_client') {
                window.location.href = process.env.VUE_APP_TEACHER as string
            } else {
                router.push('/login')
            }
        }
    }
}
// 获取登录参数href
const getQuery = () => {
    const appCode = getUrlParam('appCode')
    const routerParam: any = {}
    if (appCode) {
        // 判断需要传递的值
        for (const key in RedirectRouteParam) {
            if (getUrlParam(key)) {
                routerParam[key] = getUrlParam(key)
            }
        }
    }
    return routerParam
}
// 获取登录参数href
const getParamHref = () => {
    const appCode = getUrlParam('appCode')
    if (appCode) {
        let param = '?'
        // 判断需要传递的值
        for (const key in RedirectRouteParam) {
            if (getUrlParam(key)) {
                param += `&${key}=${getUrlParam(key)}`
            }
        }
        const pageUrl = getUrlParam('pageUrl') && getUrlParam('pageUrl') != 'undefined' ? getUrlParam('pageUrl') : ''
        return (pageUrl) + param
    } else {
        return ''
    }
}
export const setupRouter = (app: App): void => {
    app.use(router)
}
export default router
