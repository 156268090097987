import loginApi from '@/api/login'
import axios from '@/config/axios'
import { dealResult } from '@/utils/request'
import { sysMsg } from 'wd-platform-library'
const appUrl = process.env.VUE_APP_URL

/**
 * 发送短信验证码
 * @param params 验证码参数
 * @returns 发送结果
 */
export const sendSmsCode = async (params?: SendSMSParams): Promise<string | null> => {
    const [result, err] = await axios.post(loginApi.sendSms, params)
    return Promise.resolve(dealResult(err, result, sysMsg))
}
/**
 * 云之家登陆
 */
export const YZJToken = async (params: any): Promise<LoginResult | null> => {
    const [result, err] = await axios.post(loginApi.YZJToken + '?ticket=' + params.ticket, { appId: params.appid, code: params.code }, { strategy: 'yzjToken', client_id: 'yzjThird' })
    return Promise.resolve(dealResult(err, result, sysMsg))
}
/**
 * 钉钉登录
 */
export const DingDingToken = async (params: any): Promise<LoginResult | null> => {
    const [result, err] = await axios.post(loginApi.YZJToken + '?code=' + params.code, { appId: '', code: params.code }, { dingAppId: params.appid, appCode: params.appCode, strategy: 'dingTalk', client_id: 'ding_talk' })
    return Promise.resolve(dealResult(err, result, sysMsg))
}

/**
 * 钉钉扫码登陆
 */
export const DingScanLogin = async (params: any): Promise<LoginResult | null> => {
    const [result, err] = await axios.get(loginApi.pwscan, { code: params.code }, { dingAppId: params.appid, appCode: params.appCode, strategy: 'dingTalk', client_id: 'ding_talk' })
    return Promise.resolve(dealResult(err, result, sysMsg))
}

/**
 * 综合判断登录
 */
export const getUserLoginToken = async (params: any): Promise<LoginResult | null> => {

    if (params.clientType && params.clientType === 'dingding') {
        return DingDingToken(params)
    } else {
        return YZJToken(params)
    }
}
/**
 * token登录
 * @param params 登录参数
 * @returns 登录结果
 */
export const loginToken = async (params: LoginParams): Promise<LoginResult | null> => {
    const [result, err] = await axios.post(loginApi.loginToken, params, { client_id: 'personality' })
    return Promise.resolve(dealResult(err, result, sysMsg))
}

/**
 * 获取验证码
 * @param params 验证码获取参数
 * @returns 验证码地址
 */
export const getValidateCode = (params: { mobile: string, time: number }): string => {
    return `${appUrl}${loginApi.imgCode}?mobile=${params.mobile}&time=${params.time}`
}
