
import {
    defineComponent,
    reactive,
    ref
} from 'vue'
import { ITabs, IDialog, IVxeTable, sysMsg } from 'wd-platform-library'
import { DialogDataInterface } from 'wd-platform-library/lib/src/IDialog/src/IDialogInterFace'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import {
    TabDataInterface,
    TabsInterface
} from 'wd-platform-library/lib/src/ITabs/src/ITableInterface'
import {
    ResultInterFace,
    TableDataInterFace
} from 'wd-platform-library/lib/src/IVxeTable/src/IVxeTableInterFace'
import {
    batchDelMsg,
    batchReadMsg,
    selectMesageList,
    selectMsgTitleList
} from '@/service/msg'
export default defineComponent({
    setup () {
        const userStore = useUserStore()
        const checkBoxList = ref<any>([])
        const msgTableRef = ref()
        const currentAppData = ref<{ label: string; value: string } | null>(null)
        const tabsOption: TabsInterface = reactive({
            list: []
        })
        const dialogData = reactive<DialogDataInterface>({
            loading: false,
            width: 800, // 弹框宽度
            show: false, // 弹框是否展示
            title: '消息提醒' // 弹框名字
            // onCancel: () => {
            //     dialogData.show = false
            //     currentAppData.value = null
            // }
        })
        const tableData = reactive<TableDataInterFace>({
            type: 'message-table', // 每个表格唯一表示符
            loading: false,
            tableInfo: {
                columns: [
                    {
                        field: 'messageContent',
                        minWidth: 200,
                        title: '消息内容',
                        slots: {
                            default: 'messageContent_template'
                        }
                    },
                    {
                        field: 'sendTime',
                        width: 180,
                        title: '发送时间'
                    }
                ], // 表头配置
                data: [] // 数据集合
            },
            callback: async (pageInfo: any) => {
                dialogData.loading = true
                const resultData = await selectMesageList({
                    current: pageInfo.currentPage,
                    search: {
                        messageTypeId: currentAppData.value
                            ? currentAppData.value.value
                            : ''
                    },
                    size: pageInfo.pageSize
                })
                dialogData.loading = false
                let resultList: MessageResult[] = []
                let total = 0
                if (resultData) {
                    resultList = resultData.result.records
                    total = resultData.result.total
                }
                return {
                    resultList: resultList,
                    total: total
                } as ResultInterFace<MessageResult>
            },
            titleOperation: [
                {
                    label: '',
                    isShow: true,
                    buttonFun: '',
                    template: 'operation_template'
                }
            ],
            authControl: {
                isAutoSelect: false,
                isCheckBox: true, // 是否显示复选框
                columnDrop: false, // 是否可以拖拽
                toolbarCustom: false // 展示自定义
            }
        })
        const changTab = (data: TabDataInterface, index: string) => {
            // 默认查询消息
            currentAppData.value = data
            msgTableRef.value.initSelectTableList()
        }
        const selectCheckBox = (list:any, row:any) => {
            checkBoxList.value = list
        }
        const readMsgFun = async (data: MessageResult[]) => {
            const resultData = await batchReadMsg(data.map((item) => item.id))
            if (resultData) {
                sysMsg.successMsg(resultData)
                msgTableRef.value.initSelectTableList()
            }
        }
        const delMsgFun = async (data: MessageResult[]) => {
            const resultData = await batchDelMsg(data.map((item) => item.id))
            if (resultData) {
                sysMsg.successMsg(resultData)
                msgTableRef.value.initSelectTableList()
            }
        }
        const init = async () => {
            dialogData.show = true
            dialogData.loading = true
            const resultData = await selectMsgTitleList()
            if (resultData) {
                tabsOption.list = resultData.result.map((item) => ({
                    label: item.messageValue,
                    value: item.id
                }))
            } else {
                dialogData.loading = false
            }
        }
        return {
            checkBoxList,
            selectCheckBox,
            delMsgFun,
            readMsgFun,
            msgTableRef,
            init,
            currentAppData,
            tabsOption,
            changTab,
            dialogData,
            tableData
        }
    },
    components: {
        ITabs,
        IDialog,
        IVxeTable
    }
})
