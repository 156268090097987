// 服务分发中心
/**
 * ZNPT 智能平台
 * PRODUCT cis产品管理
 * PERMISSION CRM权限管理
 * CISEDUADM CIS新教务
 * TEACHER 教学服务平台
 * ZXZS 咨询师助手
 * TJBG 调剂报告
 * STUDENT CRM线索管理
 * EVALUATION 测评系统后台
 * MARKETING 营销系统
 * CONSULTHELPER 咨询师助手-PC端前台
 * PERFORMANCE 数字化核算系统-绩效
 */
export enum RedirectRouteCenterEnmu {
    ZNPT = '',
    PRODUCT = '/product',
    PERMISSION = '/permission',
    EDUCATIONAL = '/educational',
    CISEDUADM = '/fantMicro/eduadm',
    TEACHER = '/fantMicro/teacher',
    FANTMICRO = '/fantMicro',
    ZXZS = '/fantMicro/consulthelper',
    TJBG = '/fantMicro/consulthelper/info/adjustCollege/record',
    STUDENT = '/fantMicro/student',
    EVALUATION = '/fantMicro/evaluation',
    MARKETING = '/fantMicro/marketing',
    CONSULTHELPER = '/fantMicro/consulthelper',
    PERFORMANCE = '/fantMicro/performance',
}
// 移动端服务分发中心
/**
 * Order 移动端订单管理
 * MSTUDENT CRM线索管理
 * TJBG 调剂报告
 * MCONSULTHELPER 咨询师助手移动端
 * MEDUADM 教学服务平台移动
 * MPERFORMANCE 数字化核算系统-绩效
 */
export enum MobileRedirectRouteCenterEnmu {
    MSTUDENT = '/mstudent',
    Order = '/morder',
    ZXZS = '/mconsulthelper',
    TJBG = '/mconsulthelper/adjustDataCreateReport/detail/',
    MEDUADM = '/meduadm',
    MPERFORMANCE = '/mperformance',
}
/**
 * 重定向字段分解
 * appCode 项目
 * pageUrl 路由地址
 * auditId 审批id
 * orgId 机构id
 * id 默认详情页面id
 * tabType 标签页
 * detailId 详情id 兼容 finance 审批通知原有逻辑
 * otherCode 其他冗余
 * clientType 跳转类型 cis中有用到
 * 教学服务平台冗余字段 studentId,courseMatchTeacherModuleId,subjectCategoryType,subjectId ,productClassTypeId
 */
export enum RedirectRouteParam {
    appCode = 'appCode',
    pageUrl = 'pageUrl',
    ticket = 'ticket',
    id = 'id',
    auditId = 'auditId',
    orgId = 'orgId',
    tabType = 'tabType',
    otherCode = 'otherCode',
    detailId = 'detailId',
    otherType = 'otherCode',
    clientType = 'clientType',
    entrance = 'entrance',
    studentId= 'studentId',
    courseMatchTeacherModuleId='courseMatchTeacherModuleId',
    subjectCategoryType = 'subjectCategoryType',
    subjectId = 'subjectId',
    productClassTypeId = 'productClassTypeId',
    teacherId = 'teacherId',
    yearMonthly = 'yearMonthly'
}
// PC端项目
export const getRedirectType:{[key:string]:string } = {
    znpt: RedirectRouteCenterEnmu.ZNPT,
    cis: RedirectRouteCenterEnmu.PRODUCT, // 兼容历史项目
    product: RedirectRouteCenterEnmu.PRODUCT,
    eduadm: RedirectRouteCenterEnmu.CISEDUADM,
    permission: RedirectRouteCenterEnmu.PERMISSION,
    educational: RedirectRouteCenterEnmu.EDUCATIONAL,
    teacher_client: RedirectRouteCenterEnmu.TEACHER,
    tjbg: RedirectRouteCenterEnmu.TJBG,
    student: RedirectRouteCenterEnmu.STUDENT,
    evaluation: RedirectRouteCenterEnmu.EVALUATION,
    marketing: RedirectRouteCenterEnmu.MARKETING,
    consulthelper: RedirectRouteCenterEnmu.CONSULTHELPER,
    performance: RedirectRouteCenterEnmu.PERFORMANCE
}
// 移动端项目
export const mobileGetRedirectType:{[key:string]:string } = {
    mstudent: MobileRedirectRouteCenterEnmu.MSTUDENT,
    mtjbg: MobileRedirectRouteCenterEnmu.TJBG,
    mconsulthelper: MobileRedirectRouteCenterEnmu.ZXZS,
    meduadm: MobileRedirectRouteCenterEnmu.MEDUADM,
    mperformance: MobileRedirectRouteCenterEnmu.MPERFORMANCE
}
